// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;
// import './js/navigation.js'; 
// import Foundation from 'foundation-sites';

// import { Foundation } from './theme-critical'; // currently not using



var WebFont = require('webfontloader'); // Page base

import {
  Foundation
} from 'foundation-sites/js/foundation.core';
// import * as CoreUtils from 'foundation-sites/js/foundation.core.utils'; // not using - needed for utils ??????
// import { Box } from 'foundation-sites/js/foundation.util.box'; // Will return an object that contains the dimensions of element
// import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader'; // This will execute your callback function after all the images in your jQuery collection have loaded.
// import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard'; // Methods for keyboard interaction 
// import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'; // The media query library used by Foundation has two publicly accessible functions and two properties
// import { Motion, Move } from 'foundation-sites/js/foundation.util.motion'; // Two utils
// import { Nest } from 'foundation-sites/js/foundation.util.nest'; ??????
// import { Timer } from 'foundation-sites/js/foundation.util.timer'; // Similar to setInterval, except you can pause and resume where you left off
// import { Touch } from 'foundation-sites/js/foundation.util.touch'; // Allows you to add swipe* and pseudo-drag events to elements
// import { Triggers } from 'foundation-sites/js/foundation.util.triggers'; // Provides a number of event listeners and triggers your script can hook into
// import { Abide } from 'foundation-sites/js/foundation.abide';
// import { Accordion } from 'foundation-sites/js/foundation.accordion';
import {
  AccordionMenu
} from 'foundation-sites/js/foundation.accordionMenu'; // Page base
// import { Drilldown } from 'foundation-sites/js/foundation.drilldown'; // Page base
// import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import {
  DropdownMenu
} from 'foundation-sites/js/foundation.dropdownMenu'; // Page base
// import { Equalizer } from 'foundation-sites/js/foundation.equalizer';
// import { Interchange } from 'foundation-sites/js/foundation.interchange';
// import { Magellan } from 'foundation-sites/js/foundation.magellan';
import {
  OffCanvas
} from 'foundation-sites/js/foundation.offcanvas'; // Page base
// import { Orbit } from 'foundation-sites/js/foundation.orbit';
import {
  ResponsiveMenu
} from 'foundation-sites/js/foundation.responsiveMenu'; // Page base
// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle'; // _jwp_nav-title-bar.php
// import { Reveal } from 'foundation-sites/js/foundation.reveal';
// import { Slider } from 'foundation-sites/js/foundation.slider';
// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
// import { Sticky } from 'foundation-sites/js/foundation.sticky';
// import { Tabs } from 'foundation-sites/js/foundation.tabs';
// import { Toggler } from 'foundation-sites/js/foundation.toggler';
// import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';

Foundation.addToJquery($);
// test adding a foundation component to jQuery.

// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.

// *** Not using - needed for utils ??????
// Foundation.rtl = CoreUtils.rtl;
// Foundation.GetYoDigits = CoreUtils.GetYoDigits;
// Foundation.transitionend = CoreUtils.transitionend;
// Foundation.RegExpEscape = CoreUtils.RegExpEscape;
// Foundation.onLoad = CoreUtils.onLoad;

// *** Not using - needed for utils ??????
// Foundation.Box = Box;
// Foundation.onImagesLoaded = onImagesLoaded;
// Foundation.Keyboard = Keyboard;
// Foundation.MediaQuery = MediaQuery;
// Foundation.Motion = Motion;
// Foundation.Move = Move;
// Foundation.Nest = Nest;
// Foundation.Timer = Timer;

// Touch and Triggers previously were almost purely sede effect driven,
// so no need to add it to Foundation, just init them.

// *** Not using - needed for utils ??????
// Touch.init($);
// Triggers.init($, Foundation);
// MediaQuery._init();

// Foundation.plugin(Abide, 'Abide');
// Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(AccordionMenu, 'AccordionMenu'); // Page base
// Foundation.plugin(Drilldown, 'Drilldown'); // Page base
// Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(DropdownMenu, 'DropdownMenu'); // Page base
// Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
Foundation.plugin(OffCanvas, 'OffCanvas'); // Page base
// Foundation.plugin(Orbit, 'Orbit');
Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu'); // Page base
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
// Foundation.plugin(Reveal, 'Reveal');
// Foundation.plugin(Slider, 'Slider');
// Foundation.plugin(SmoothScroll, 'SmoothScroll');
// Foundation.plugin(Sticky, 'Sticky');
// Foundation.plugin(Tabs, 'Tabs');
// Foundation.plugin(Toggler, 'Toggler');
// Foundation.plugin(Tooltip, 'Tooltip');
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

// export { Foundation };


// import {
//     initializer
// } from './js/foundation';
// require('waypoints/lib/noframework.waypoints.min');
// import 'slick-carousel';
// import 'slick-carousel/slick/slick.css';
// import './styles/critical.scss';
import './theme.scss';



// initializer.key_initFoundation();
// jQuery(document).foundation();
// cannot apply update. Need to do a full reload - only accept if full relod is not needed
// if(module && module.hot){
//     module.hot.accept();
// }   
// ****************************** // 
// ****** WRAPPER  ****** // 
// ****************************** // 
// IIFE - Immediately Invoked Function Expression
(function (bptcode) {

  // The global jQuery object is passed as a parameter 
  bptcode(window.jQuery, window, document);

}(function ($, window, document) {

  // function adjustMenuWidth(arg) {
  //     let argOffset = parseInt(arg) + 70;
  //     let argOffsetPixel = argOffset + 'px';

  //     console.log('width = ' + argOffsetPixel);
  //     $('#sub-menu .parent-width').css({
  //         'max-width': argOffsetPixel,
  //     });
  // }

  // $(window).on('load', function () {
  //     const parentMenuWidth = $('#main-nav-mega').css('width');
  //     adjustMenuWidth(parentMenuWidth);

  // });

  // $(window).on('load', function () {
  //     let parentMenuWidth = $('#main-nav-mega').css('width');
  //     adjustMenuWidth(parentMenuWidth); 

  // });


  // The $ is now locally scoped  
  $(document).ready(function () {
    // *** CODE HERE *** //
    $(document).foundation();
    if ($('[data-equalizer]').length > 0) {
      setTimeout(() => Foundation.reInit('equalizer'), 250);
    }


    WebFont.load({
      custom: {
        families: ['Inter', 'Golos Text'],
      }
    });


    $('.no-fouc').removeClass('no-fouc');

    function arrowButtons() {
      let arrowButtons = $('.button.arrow');
      if (arrowButtons.length > 0) {
        arrowButtons.each(function () {
          $(this).append(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.287" height="21.439" viewBox="0 0 16.287 21.439">
            <defs>
              <linearGradient id="linear-gradient" y1="0.5" x2="1.103" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#f58706"/>
                <stop offset="1" stop-color="#b91e79"/>
              </linearGradient>
            </defs>
            <g id="Group_6325" data-name="Group 6325" transform="translate(16.287 21.439) rotate(180)">
              <g id="Group_4032" data-name="Group 4032">
              <path id="Path_5760" data-name="Path 5760" d="M16.069,2.045,12.618.109a.852.852,0,0,0-.834,0L5.294,3.75C1.88,5.665,0,8.14,0,10.719s1.88,5.054,5.294,6.969l6.49,3.641a.852.852,0,0,0,.834,0l3.451-1.936a.426.426,0,0,0,0-.743L1.931,10.719,16.069,2.788a.426.426,0,0,0,0-.743" transform="translate(0 0)" fill="url(#linear-gradient)"/>
              <path id="Path_5759" data-name="Path 5759" d="M16.069,2.045,12.618.109a.852.852,0,0,0-.834,0L5.294,3.75C1.88,5.665,0,8.14,0,10.719s1.88,5.054,5.294,6.969l6.49,3.641a.852.852,0,0,0,.834,0l3.451-1.936a.426.426,0,0,0,0-.743L1.931,10.719,16.069,2.788a.426.426,0,0,0,0-.743" transform="translate(0 0)" fill="#7577be"/>
              </g>
            </g>
          </svg>`);
        });
      }
    }
    arrowButtons();
    window.almComplete = function (alm) {
      arrowButtons();
    };

    // AJAX POSTS
    // function ajaxRequest(clickedPage) {
    //   const ajaxURL = ajaxpagination.ajaxurl;
    //   // improve
    //   const queryVars = ajaxpagination.query_vars;
    //   let page;
    //   if (clickedPage) {
    //     page = clickedPage;
    //   } else {
    //     page = 1;
    //   }
    //   // end improve
    //   $.ajax({
    //     url: ajaxURL,
    //     type: 'post',
    //     data: {
    //       action: 'ajax_pagination',
    //       page: page
    //     },
    //     success: function (html) {
    //       // console.log('success: ', html);
    //       // $('#posts-listing-container').find( 'article' ).remove();
    //       // $('.pagination').remove();
    //       $('#posts-listing-container').append(html);
    //     },
    //     error: function (error) {
    //       console.log(error);
    //     }
    //   });
    // }
    // $(document).on('click', '.pagination a', function (event) {
    //   const $clickedPage = $(this).text();
    //   event.preventDefault();
    //   ajaxRequest($clickedPage);
    // });
    // ajaxRequest();

    // *** END CODE *** //
    // ****************************** // 
    // ****** END WRAPPER  ****** // 
    // ****************************** //
  });
}));